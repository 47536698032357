import { environment } from "src/environments/environment";

export const GlobalComponent = {
    // Api Calling
    API_URL: environment.API_URL,
    // API_URL : 'http://127.0.0.1:3000/',
    headerToken: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },

    // Auth Api
    AUTH_API: environment.API_URL+"api/Portal/Users/LogIn",

    IMAGE_API: "https://file.valoracatalog.com",
    // Auth A
    classifications: "api/Clasifications",

    company: "api/Portal/Users/ChangeLoggedCompany",
    companyData: "/api/Company",

    currencies: "/api/Currencies",

    logaction: "api/Portal/ActionLogs",
    //Brands API
    brands: "api/Portal/Brands",


    //Types API

    types: "api/Portal/Types",
    statuses: "api/Clasifications/Statuses",

    products: "api/Products",

    //Sites API
    sites: "api/Portal/Sites",

    units: "api/Units",
    //Locations API
    locations: "api/Locations",
    catalog: "api/Catalogs",
    warehouses: "api/WareHouse",

    customers: "api/Customer",
    users: "api/Portal/Users",

    categories: "api/Portal/Categories",

    suppliers: "api/Portal/Suppliers",

    countries: "api/Portal/Countries",
    dateformat: "api/DateFormats",
    timezone: "api/TimeZones",

    saveimage: "/api/Portal/Files",
    // Products Api

    product: 'apps/product',
    productDelete: 'apps/product/',

    // Orders Api
    order: 'apps/order',
    orderId: 'apps/order/',

    // Customers Api
    customer: 'apps/customer',
    //Locations API
    manufacturers: "api/Portal/Manufacturers",
    //Groups
    groups: "api/FGroups",
    //Groups
    groupsPerms: "api/GroupPermissions",
    //User Perms Assign
    permsAssign: "api/PermAssign",
    //auth api
    AUTH_APIV2: "api/Auth",
    productReader: "api/ProductReader",
}